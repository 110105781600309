.main-holder {
    @include padding-top(1);
    padding-bottom: 3rem;

    @include mq($bp3) {
        //@include padding-bottom(2);
        padding-bottom: 0;
    }
}

.main {
    @include box-(12, 1, 1);

    @include mq($bp4) {
        @include box-(12, 1, 1);
        @include padding-bottom(2);
    }
}

// Tooltip
.master-tooltip {
    cursor: pointer;
    cursor: hand;
}
.tooltip {
    display: none;
    position: absolute;
    background-color: $alpha-ui-color;
    border-radius: $radius;
    @include padding-tb(0.2, 0.2);
    padding-left: 1em;
    padding-right: 1em;
    @include text-dimensions(14, 1);
    color: #fff;
    transition: opacity 0.3s ease-in-out;
}
.tooltip.visible {
    opacity: 1;
}

// Tabs
.tab-content > .tab-pane {
    display: none;
}
.tab-content > .active {
    display: block;
}
.fade {
    opacity: 0;
    -webkit-transition: opacity 0.15s linear;
    -o-transition: opacity 0.15s linear;
    transition: opacity 0.15s linear;
}
.fade.in {
    opacity: 1;
}

//
// Breadcrumbs
//
.breadcrumb {
    color: #999;
}
.breadcrumb a {
    text-transform: none;
}
.breadcrumb p {
    padding-top: 0;
}

//
// Right column main
//
.content-holder {
    @include box-(12, 1, 1);
}
.main-col-8 {
    @include box-(12, 1, 1);
    padding-top: 0;

    @include mq($bp3) {
        @include box-(8, 1, 2);
    }
}
.right-col-4 {
    @include box-(12, 1, 1);
    @include padding-tb(0, 1);

    @include mq($bp3) {
        @include box-(4, 2, 1);
    }
}
.right-col-holder p {
    padding: 0;
}
// .right-col-holder img {
// 	height: auto !important;
// 	@include border-radius(8px);
// 	width: 100% !important;
// 	margin-bottom: 0.5em;

// 	@include mq($bp2) {
// 		float: left;
// 		width: 50% !important;
// 		padding-left: 0.2em;
// 		padding-right: 0.2em;
// 		margin-bottom: 0.4em;
// 	}

// 	@include mq($bp3) {
// 		width: 100% !important;
// 		padding: 0;
// 		margin-bottom: 0.5em;
// 	}
// }

//
// Two columns
//
.main-col-6 {
    @include box-(12, 1, 1);

    @include mq($bp3) {
        @include box-(6, 1, 1);
    }

    @include mq($bp4) {
        @include box-(6, 0, 3);
    }
}
.main-col-6.right {
    @include mq($bp4) {
        padding-right: 0;
    }

    form p {
        padding-top: 0;
    }
}

.main-col-6 label {
    font-weight: bold;
}

//
// Maps
//

.parseMap {
    width: 100% !important;
    position: relative;
    z-index: 0;
    top: 0;
    @include margin-tb(2, 0);

    img {
        max-width: none; // Zoomlevel glitch fix
    }
}

// Only show larger map anchor on phones
.btn-large-map {
    display: block;
    float: right;
    width: 60% !important;

    @include mq($bp2) {
        width: 40% !important;
    }

    @include mq($bp3) {
        display: none !important;
    }
}

.maps-holder {
    position: relative;
    // margin-bottom: -3em;
}
.maps-ui-holder {
    position: absolute;
    width: 100%;
    top: 0;
    z-index: 1;
}
.locationSearch {
    display: none;
    text-align: right;

    @include mq($bp3) {
        display: block;
        @include box-(8, 0, 0);
        float: right;
    }

    @include mq($bp4) {
        @include box-(6, 0, 0);
        float: right;
    }

    .locationSearchAddress {
        @include em-margin-lr(0, 1);
        padding-top: 0;
        padding-bottom: 0;
        line-height: 1.5;
        height: 2.5em;

        @include mq($bp3) {
            @include box-(7, 1, 1);
            float: right;
        }

        @include mq($bp4) {
            @include input-m;
            line-height: 1.5;
            @include box-(7, 1, 1);
            float: right;
            @include em-margin-lr(0, 1);
        }

        @include mq($bp5) {
            @include box-(8, 1, 1);
            @include em-margin-lr(0, 1);
            float: right;
        }
    }
    label {
        display: none;
    }
    .input-submit {
        @include btn-s;
        @include mq($bp4) {
            @include btn-m;
        }
        float: right;
        @include em-margin-lr(0, 1);
    }
}

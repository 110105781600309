// Clearfix.
// @extend %clearfix;

%clearfix {
	*zoom : 1;

	&:before,
	&:after {
		display: table;
		content : "";
	}

	&:after {
		clear : both;
	}
}

// Prevent Long URL’s From Breaking Out of Container.
// http://css-tricks.com/snippets/css/prevent-long-urls-from-breaking-out-of-container

%word-break {
	-ms-word-break: break-all;
	word-break: break-all;
	
	// Non standard for webkit
	word-break: break-word;
	hyphens: auto;
}

// A better way to hide text
// http://www.zeldman.com/2012/03/01/replacing-the-9999px-hack-new-image-replacement/
%hide-text {
	text-indent: 100%;
	white-space: nowrap;
	overflow: hidden;
}

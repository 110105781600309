// Functionality for all images with native lazy loading enabled
.no-js img[loading='lazy'].img-hidden {
    opacity: 1;
}
.js img[loading='lazy'] {
    opacity: 0;
}

.js img[loading='lazy'].loaded {
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
    transition-delay: 0.5s;
}

// Right column photobar
.right-col .photoswipe-grid .grid-item {
    width: 100%;
}

// Pagination

.pagination {

	a,
	span {
		padding: 0 10px;
		border: 1px solid $gamma-ui-color;
		margin-left: -1px;
	}

	span {
		color: $beta-text-color;
	}

	@include pagination;
}

.pagination-centered {

	a,
	span {
		padding: 0 10px;
		border: 1px solid $gamma-ui-color;
		margin-left: -1px;
	}

	span {
		color: $beta-text-color;
	}

	@include pagination-centered;
}

//
// Media object
// ---
// http://www.stubbornella.org/content/2010/06/25/the-media-object-saves-hundreds-of-lines-of-code/
//
// The media object is an image to the left, with descriptive content to the right.
// Use a grid class to determine the size of the image. The content will take the remaining space.
//
// Example usage:
// <article class="media">
//   <div class="media__img grid-4">
//     <img src="http://placekitten.com/200/300" alt="placekitten" />
//   </div>
//   <div class="media__body">
//     The media object is an image to the left, with descriptive content to the right.
//   </div>
// </article>

.media {
	@extend %clearfix;
	display: block;

	@include margin-tb(0, 1);
}

	.media__img,
	.media__img--rev {
		@include margin-bottom(1);

		@include mq($bp3) {
			@include margin-bottom(0);
		}
	}

	.media__img {
		float: left;
	}

	.media__img--rev {
		float: right;
	}

	.media__body {
		overflow: hidden;
		@include em-padding-lr(1, 1);
	}

// Reduce the size of the modal window
.tingle-modal-box {
    width: 50% !important;
    max-width: 420px;
}

// Custom svg icon
.icon-close {
    display: block;
    width: 29px;
    height: 29px;
    transition: all 0.3s ease;

    &:hover,
    &:focus {
        transform: rotate(180deg);
        color: #fff;
    }

    @media (min-width: 540px) {
        &:hover {
            color: $alpha-ui-color;
        }
    }
}

@media (max-width: 540px) {
    .tingle-modal-box {
        width: auto !important;
    }
}

// Reduce the padding on mobile
.tingle-modal-box__content {
    padding: 0rem !important;
}

// Add gold to the mobile close button
@media (max-width: 540px) {
    .tingle-modal__close {
        background-color: $alpha-ui-color !important;
    }
}

.tingle-modal__closeLabel {
    margin-left: 1rem;
    font-family: $alpha-font-family !important;
}

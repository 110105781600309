//
// Grid
//
.photoswipe-grid {
    @include margin-tb(1, 1);
}

.grid-item a {
    transition: opacity 300ms ease-in;
}

.grid-item.image-loaded a {
    opacity: 1 !important;
}

.photoswipe-grid figure {
    display: block;
    float: left;
    border: none;
}

.photoswipe-grid a {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-touch-callout: none;
    display: block;
    border: none;
}

.photoswipe-grid img {
    width: 100%;
    max-width: 100%;
    height: auto;
    border: 0;
}

.photoswipe-grid .grid-sizer,
.photoswipe-grid .grid-item {
    width: 50%;
    padding-right: 6px;
    padding-bottom: 12px;
    padding-left: 6px;

    @include mq($bp2) {
        width: 33.3333%;
        padding-right: 6px;
        padding-bottom: 12px;
        padding-left: 6px;
    }

    @include mq($bp3) {
        width: 20%;
        padding-right: 12px;
        padding-bottom: 24px;
        padding-left: 12px;
    }

    &.highlight {
        width: 100%;
        padding-right: 6px;
        padding-bottom: 12px;
        padding-left: 6px;

        @include mq($bp2) {
            width: 66.6666%;
            padding-right: 6px;
            padding-bottom: 12px;
            padding-left: 6px;
        }

        @include mq($bp3) {
            width: 40%;
            padding-right: 12px;
            padding-bottom: 24px;
            padding-left: 12px;
        }
    }
}

/**** Isotope CSS3 transitions ****/

// .isotope-ready,
// .isotope-ready .grid-item {
//     transition-duration: 0.5s;
// }

.isotope-ready {
    transition-property: height, width;
}

// .isotope-ready .grid-item {
//     transition-property: transform, opacity;
// }

/**** disabling Isotope CSS3 transitions ****/

// .isotope-ready.no-transition,
// .isotope-ready.no-transition .grid-item,
// .isotope-ready .grid-item.no-transition {
//     transition-duration: 0s;
// }

/* End: Recommended Isotope styles */

//
// Chopstick settings
//

// Debug

$debug-mode: false; // toggles the debug mode

// Typography

$show-baseline-grid: false; // toggle the visibility of the baseline (true/false)
$baseline-holder: holder; // set the classname of the element which will show the baseline
$grid-color: rgba(#000, 0.1); // set the baseline grid color and opacity

$alpha-font-family: 'Gotham-web', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
$alpha-font-size: 13; // pixel-value
$alpha-font-weight: 400; // Gotham book
$alpha-line-height: 1.7; // em-value
$alpha-baseline-grid: 7; // pixel-value
$alpha-leading: 1; // multiplier

$beta-font-family: $alpha-font-family;
$beta-font-weight: 500;

// Grid

$grid-gutter: 21;
$grid-column: 78;
$grid-columns: 12;

// Breakpoints calculated in em's, always based on 16px base-value

$bp1: 320/16 * 1em;
$bp2: 480/16 * 1em;
$bp3: 680/16 * 1em;
$bp4: 960/16 * 1em;
$bp5: 1140/16 * 1em;

// the last breakpoint is calculated in shared/_grid.scss
// $bp5: #{1200 / $alpha-font-size}em;

// Modular scale settings

$ratio: 1.314;
$importantnumber: 44;
$leading: 1;
$base-size: $alpha-font-size/16 * 1em;

// manual modular scale font sizes
// based on http://modularscale.com/scale/?px1=16&px2=18&ra1=1.333&ra2=0

$ms-2: 12;
$ms-1: 14;
$ms1: 18;
$ms2: 21;
$ms3: 23;
$ms4: 28;
$ms5: 31;
$ms6: 37;
$ms7: 42;
$ms8: 50;
$ms10: 56;
$ms11: 67;
$ms12: 75;
$ms13: 89;
$ms14: 100;

// UI

$radius: 0px;
$border-width: 1px;
$border-style: solid;
$depth: 0%; // Range: 0-100%
$contrast: 91%; // Range: 0-100%
$shadow: 21%; // Range: 0-100%
$highlight: 0%; // Range: 0-100%

//
// Color variables (defaults by https://github.com/mrmrs/colors)
//
// - Cool
// - Warm
// - Gray Scale
// - Semantic
//

// Cool

$aqua: #7fdbff;
$blue: #0074d9;
$navy: #001f3f;
$teal: #39cccc;
$green: #6a844c;
$olive: #3d9970;
$lime: #01ff70;

// Warm

$gold: #bc9a58;
$yellow: #b59759;
$orange: #ff851b;
$red: #ff4136;
$fuchsia: #f012be;
$purple: #b10dc9;
$maroon: #85144b;

// Gray Scale

$white: #fff;
$silver: #ccc;
$gray: #828282;
$gray-700: #393939;
$black: #292929;

// Semantic classes

$alpha-text-color: #393939;
$beta-text-color: $gray;

$alpha-ui-color: #b59759; // #A79461;
$beta-ui-color: $gray; // Secondary elements
$gamma-ui-color: $silver; // Used for borders
$delta-ui-color: $white; // Used for backgrounds

$error-color: $red;
$success-color: $green;
$notice-color: $yellow;

// Links

$alpha-link-color: $yellow;
$alpha-link-colorhover: darken($yellow, 20);
$alpha-link-colorfocus: $yellow;
$alpha-link-coloractive: $yellow;
$alpha-link-colorvisited: $yellow;

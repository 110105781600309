/*
	User Interface Elements
*/

// Buttons

%btn {
    border-radius: $radius;
    position: relative;
    display: block;
    width: 100%;
    text-align: center;
    padding: 0;
    margin: 0;
    overflow: hidden;
    font-family: $alpha-font-family;
    //font-weight: $beta-font-weight;
    text-transform: uppercase;
    cursor: pointer;
    text-decoration: none;
    -webkit-appearance: none;
    -webkit-font-smoothing: antialiased;
    @include transition(border 0.22s ease-in-out, color 0.22s ease-in-out, background 0.22s ease-in-out);

    &:hover,
    &:visited {
        text-decoration: none;
    }
}

// Button sizes

@mixin btn-l {
    @include em-padding-lr(2.2);
    //@include baseline-dimensions-form(1, 2, 2, 0);
    @include text-dimensions($alpha-font-size, 1.7, 0, 0.2, 0.2);
}

@mixin btn-m {
    @include em-padding-lr(0.75);
    @include baseline-dimensions-form(0, 2, 2, 0);
}

@mixin btn-s {
    @include em-padding-lr(1);
    //@include baseline-dimensions-form(0,1,1,0);
    @include text-dimensions(14, 1.5, 0, 0, 0);
}

// Button display

@mixin btn-inlineblock {
    display: inline-block;
    width: auto;
}

// Button color
// use: @mixin btn-color(color1[,color2]);

@mixin btn-color($c1: $alpha-ui-color, $c2: lighten($c1, $depth)) {
    border: $border-width $border-style $c1;

    background-color: $c1;
    @if $depth > 0 {
        background-image: linear-gradient($c1, $c2);
    }

    @if $highlight {
        box-shadow: inset 0 1px 0 rgba(#fff, $highlight/100%);
    }

    @include text-color($c1);

    &:hover,
    &:focus {
        border: $border-width $border-style lighten($c1, 100-$contrast);
        background-color: lighten($c1, 100-$contrast);
        @if $depth > 0 {
            background-image: linear-gradient(lighten($c1, 100-$contrast), lighten($c2, 100-$contrast));
        }
        @include text-color(lighten($c1, 100-$contrast));
    }

    &:focus {
        //text-decoration: underline;
    }

    &:active {
        background-color: lighten($c1, 100-$contrast);
        @if $depth > 0 {
            background-image: linear-gradient(lighten($c2, 100-$contrast), lighten($c1, 100-$contrast));
        }

        box-shadow: none;
        @include text-color(lighten($c1, 100-$contrast));
    }

    &:visited {
        @include text-color($c1);
    }
}

// Inputs

@mixin baseline-dimensions-form($msi: 0, $pt: 0, $pb: 0, $l: $alpha-leading) {
    @include baseline-dimensions($msi, $pt, $pb, $l);

    @if $msi == 0 {
        $line-height: (
                (ceil($alpha-font-size/$alpha-baseline-grid) * $alpha-baseline-grid)+ ($alpha-baseline-grid * $l)
            ) /
            $alpha-font-size;
        $padding-top: $alpha-baseline-grid/$alpha-font-size * $pt * 1em;
        $padding-bottom: $alpha-baseline-grid/$alpha-font-size * $pb * 1em;
        height: $line-height + $padding-top + $padding-bottom;
    }

    @if $msi != 0 {
        $line-height: ((ceil(ms($msi) / $alpha-baseline-grid) * $alpha-baseline-grid)+ ($alpha-baseline-grid * $l))/
            (ms($msi));
        $padding-top: $alpha-baseline-grid * $pt/ms($msi) * 1em;
        $padding-bottom: $alpha-baseline-grid * $pb/ms($msi) * 1em;
        height: $line-height + $padding-top + $padding-bottom;
    }
}

@mixin input-l {
    @include em-padding-lr(1);
    @include baseline-dimensions-form(1, 2, 2, 0);
}

@mixin input-m {
    @include em-padding-lr(1);
    @include baseline-dimensions-form(0, 2, 2, 0);
}

@mixin input-s {
    @include em-padding-lr(0.5);
    //@include baseline-dimensions-form(0,1,1,0);
    @include text-dimensions(14, 1.5, 0, 0, 0);
}

// Pre- & postfix mixins

@mixin postfix {
    border-radius: 0 $radius $radius 0;
    border-left: 0;
}

@mixin prefix {
    border-radius: $radius 0 0 $radius;
    border-right: 0;
}

// Alerts

%alert {
    position: relative;
    padding: em($alpha-baseline-grid * 2) em($alpha-baseline-grid * 3);
    margin-bottom: em($alpha-baseline-grid * 2);
    cursor: pointer;
    @include block-skin;

    a {
        text-decoration: underline;
    }

    a:hover,
    a:active,
    a:focus {
        text-decoration: none;
    }

    a.close {
        position: absolute;
        top: #{$alpha-line-height/2}em;
        right: #{$alpha-line-height/2}em;
        display: block;
        line-height: 1;
        text-decoration: none;
    }

    p {
        @include baseline-dimensions(0, 1, 1);
    }
}

@mixin alert {
    @extend %alert;

    strong {
        @include text-color($gamma-ui-color);
    }

    a {
        @include text-color($gamma-ui-color);
    }
}

@mixin alert-error {
    @extend %alert;
    @include block-skin($error-color);

    strong {
        @include text-color($error-color);
    }

    a {
        @include text-color($error-color);
    }
}

@mixin alert-success {
    @extend %alert;
    @include block-skin($success-color);

    strong {
        @include text-color($success-color);
    }

    a {
        @include text-color($success-color);
    }
}

@mixin alert-notice {
    @extend %alert;
    @include block-skin($notice-color);

    strong {
        @include text-color($notice-color);
    }

    a {
        @include text-color($notice-color);
    }
}

/*
	Pagination mixin
*/

%pagination {
    @extend %clearfix;
    width: 100%;
    overflow: hidden;

    ul {
        margin: #{$alpha-line-height}em 0;
        @include horizontal-nav;
    }

    a,
    span {
        display: block;
        float: left;
    }
}

@mixin pagination {
    @extend %pagination;
}

@mixin pagination-centered {
    @extend %pagination;

    .inner {
        float: left;
        position: relative;
        left: 50%;
    }

    ul {
        overflow: hidden;
        position: relative;
        left: -50%;
    }
}

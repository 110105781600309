// Responsive media embedding, like an iframe

.media-embed {
	width: 100%;
	height: 0;
	padding-bottom: 62.5%;
	position: relative;
	overflow: hidden;
	@include margin-bottom(1.5);

	iframe {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
	}
}

// Basis class to easily hide elements (for js toggle functionality, ...)
.is-hidden.is-hidden {
	display: none;
}

//
// Base
// ---
// Default markup for the most common HTML elements, based on the settings file.
//

*,
*:before,
*:after {
    box-sizing: inherit;
}

html {
    box-sizing: border-box;
    font: $alpha-font-weight #{$alpha-font-size/16 * 100%}/#{$alpha-line-height} $alpha-font-family;
    -webkit-overflow-scrolling: touch;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    background-color: #f5f4ef;

    // @include mq($bp5) {
    //     font-size: $alpha-font-size/16 * 112.5%;
    // }
}

body {
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizelegibility;
    color: $alpha-text-color;
}

::-moz-selection {
    background-color: $alpha-ui-color;
    color: lighten($alpha-ui-color, 65%);
    text-shadow: none;
}

::selection {
    background-color: $alpha-ui-color;
    color: lighten($alpha-ui-color, 65%);
    text-shadow: none;
}

hr {
    border: 1px solid lighten($alpha-text-color, 60);
    border-width: 1px 0 0;
    clear: both;
    margin: 0 0 $alpha-line-height * 1em;
}

strong {
    font-weight: 500;
}

//
// Fix a > img problem
//

img {
    vertical-align: top;
    border: 0;
}

// variables
$showMenuBreakpoint: 895/16 * 1em;

//
// Header
//

.header {
    @include box-(12, 1, 1);
}

.header.inner {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
}

.header-holder {
    @include padding-tb(0, 0);
}

.header-holder .inner {
    @include padding-tb(0.47, 0.75);
}

.nav-group {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

// Logo
.logo {
    font-family: $alpha-font-family;
    font-style: italic;
    font-weight: $alpha-font-weight;
    @include text-dimensions($alpha-font-size, 1, 0, 0, 0);
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    flex: 1 1 0%;

    a {
        background-image: url('../Img/logo-resto.svg');
        background-position: left top;
        background-repeat: no-repeat;
        background-position: left center;
        background-size: auto 100%;
        height: 90px;
        display: block;
        width: 274px;
        @extend %hide-text;
    }
}

.main-nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    @include margin-tb(0, 0.25);

    ul {
        font-family: $alpha-font-family;
        @include list-nav;
        @include text-dimensions($alpha-font-size, 1, 0, 0, 0);
    }

    ul li {
        border-bottom: 1px solid lighten($silver, 10%);

        a {
            width: 100%;
            display: block;
            @include padding-tb(0.25, 0.25);
        }
    }

    a {
        color: $black;
        text-decoration: none;
        text-transform: uppercase;
    }

    a:hover,
    .selected a {
        color: darken($alpha-ui-color, 0);
    }
}

.main-nav {
    @include mq($showMenuBreakpoint) {
        display: flex !important;
        margin-top: 1rem;

        ul {
            @include horizontal-nav();
            @include text-dimensions(13, 1, 0, 0);
        }

        ul li {
            margin-left: 1.5rem;
            border-bottom: none;

            @include mq($bp4) {
                margin-left: 2.4rem;
            }

            &.selected {
                border-bottom: 1px solid $alpha-ui-color;
            }

            a {
                padding: 0;
            }
        }

        .btn-l {
            color: $alpha-ui-color;
            margin-left: 2.7rem;
        }
    }
}

.mobile-nav {
    @include padding-tb(0.5, 0.5);
    @include box-(12, 2, 2);
    display: flex;
    flex-direction: column;

    ul {
        @include list-nav;
        text-align: center;
    }

    ul li {
        border-bottom: 1px solid lighten($silver, 10%);
    }

    // Main mobile menu items
    ul:first-child li {
        a {
            color: #000;
        }

        &.selected a {
            color: $alpha-ui-color;
        }
    }

    // Meta mobile menu items
    ul:nth-child(2n) li {
        a {
            color: $gray;
        }

        &.selected a {
            color: $alpha-ui-color;
        }

        &:last-child {
            border-bottom: 0;
        }
    }

    ul li a {
        display: block;
        @include text-dimensions(14, 1, 0, 0.3, 0.3);
        text-decoration: none;

        &:hover,
        &:focus {
            color: $alpha-ui-color;
        }
    }

    // CTA button
    .btn-l {
        @include margin-tb(1, 1);
    }

    // Hide when main nav needs to be shown
    @include mq($showMenuBreakpoint) {
        display: none !important;
    }
}

//
// Hamburger Menu
//
$hamburger-types: (spin);
$hamburger-layer-width: 36px;
$hamburger-layer-height: 1px;
$hamburger-layer-spacing: 7px;
@import '../../../../../node_modules/hamburgers/_sass/hamburgers/hamburgers.scss';
.hamburger {
    outline: none;
    @include mq($showMenuBreakpoint) {
        display: none;
    }
}
.hamburger-label {
    display: block;
    text-transform: lowercase;
    font-size: rem(13);
    color: #000;
    line-height: 1;
    margin-top: 0.75rem;

    .hamburger.is-active & {
        color: $gold;
    }
}
.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
    background-color: $gold;
}

//
// Meta navigation and languages
//
.top-nav {
    ul {
        font-family: $alpha-font-family;
        @include list-nav;
        @include text-dimensions($alpha-font-size, 1, 1);
    }

    a {
        color: $gray;
        text-decoration: none;
    }

    a:hover,
    .selected a {
        color: $black;
    }

    .lang-nav {
        border-left: 1px solid $gold;
        @include em-margin-lr(1.5, 0);
        @include em-padding-lr(0.5, 0);
    }

    .lang-nav ul li,
    .meta-nav ul li {
        @include text-dimensions($alpha-font-size, 1, 0, 0, 0);
    }

    @include mq($showMenuBreakpoint) {
        ul {
            @include horizontal-nav();
            @include text-dimensions(14, 1, 0);
        }

        li {
            margin-left: 1.9rem;
        }
    }
}

.meta-item,
.meta-item a {
    color: lighten($gray, 8%);
    font-size: rem(13);

    @include mq($showMenuBreakpoint) {
        display: none !important;
    }

    &:last-child {
        border-bottom: none !important;
    }
}

.meta-nav {
    display: none;
    flex-direction: row;
    align-items: center;

    @include mq($showMenuBreakpoint) {
        display: flex;
    }
}
.meta-nav ul {
    display: flex;
}
.top-nav .meta-nav ul li {
    line-height: em(26);
}

// Social media icons
.meta-nav .social-media {
    height: 20px; // For divider
    display: flex;
    align-items: center;
    color: $gray;
    margin-left: 1.8rem;
    padding-left: 0.2em;

    @include mq($bp3) {
        border-left: 1px solid $gold;
    }

    ul li {
        padding: 0;
        margin-left: 0.8em;
        @include text-dimensions(20, 1, 0);
    }

    ul li a {
        display: flex;
        justify-content: baseline;
    }
}

.social-media .icon {
    width: 16px;
    height: 16px;
}

//
// module: home
//

.home-main-holder {
    @include padding-top(1);
    @include mq($bp3) {
        @include padding-top(2);
    }
}

// Square grid: https://codepen.io/krystof-k/pen/vdNQGB
.home-grid {
    margin-top: 3rem;
    margin-bottom: 3rem;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    column-gap: 50px;
    row-gap: 80px;

    > .home__item {
        grid-column: span 2;
        @include mq($bp4) {
            grid-column: span 1;
        }
    }

    @include mq($bp4) {
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: 1fr;
    }

    @include mq($bp4) {
        &::before {
            content: '';
            width: 0;
            padding-bottom: 100%;
            grid-row: 1 / 1;
            grid-column: 1 / 1;
        }

        > *:first-child {
            grid-row: 1 / 1;
            grid-column: 1 / 1;
        }
    }
}

.home-grid > .home__item {
    .block-content {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    h3 {
        margin-bottom: 2.6rem;
    }
    h3::after {
        content: '';
        display: block;
        width: 50px;
        margin: 0 auto;
        border-top: 1px solid #000;
        margin-top: 2.6rem;
    }

    p,
    h3 {
        text-align: center;
        padding: 0;
        display: block;
        padding-left: 2rem;
        padding-right: 2rem;

        @include mq($bp3) {
            padding-left: 5rem;
            padding-right: 5rem;
        }
    }

    .btn {
        margin-top: 0.5rem;
    }

    // Make any image fit the whole block
    img {
        display: block;
        object-fit: cover;
        width: 100% !important;
        height: 100vw !important;

        @include mq($bp4) {
            width: 100% !important;
            height: 100% !important;
        }
    }
}

//
// Typography
// ---
// Default markup for typographic elements, based on the settings file.
//

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    font-weight: $alpha-font-weight;
    text-rendering: optimizelegibility;
}

h1 {
    @include baseline-dimensions(3, 3, 3);
}

h2 {
    @include baseline-dimensions(2, 3, 3);
    font-weight: 500;
}

h3 {
    @include baseline-dimensions(1, 3, 1);
    color: #000;
    font-size: em(18);
    font-weight: 500;
}

h4,
h5,
h6 {
    font-weight: $beta-font-weight;
    @include baseline-dimensions(0, 3, 1);
}

p,
ol,
ul,
dl,
table,
address {
    @include baseline-dimensions(0, 1, 2, 1.2);
    font-size: 1rem;
}

pre {
    tab-size: 2;
    overflow: auto;
}

ul,
ol,
blockquote {
    padding-left: 1em;
}

blockquote {
    color: #c59d5f;
    margin: 36px 0;
    padding-left: 20px;
    font-size: 1.5rem;
    line-height: 1.51;
    line-height: 1.5;
    text-indent: 0;
    border-left: 10px solid #c59d5f;
    color: #c59d5f;
    quotes: none;

    p {
        font-size: 1.5rem;
        padding: 0;
    }

    @include mq($bp3) {
        padding-left: 36px;
    }
}

caption {
    font-family: $alpha-font-family;
    text-align: left;
    border-bottom: 1px solid lighten($alpha-text-color, 50);
    font-weight: $beta-font-weight;
    text-align: center;
    @include padding-bottom(0.5);
    @include margin-bottom(0.5);
}

img {
    max-width: 100%;
}

//
// Links
//

a {
    text-decoration: none;
    color: $alpha-link-color;
    @include transition(color 0.22s ease-in);

    &:visited {
        color: $alpha-link-colorvisited;
    }

    &:hover {
        text-decoration: underline;
        color: $alpha-link-colorhover;
    }

    &:focus {
        outline: thin dotted;
        color: $alpha-link-colorfocus;
    }

    &:hover,
    &:active {
        outline: 0;
    }
}

// .main a:not(.btn, .btn-tab, .btn-secondary) {
// 	border-bottom: 2px solid $alpha-link-color;
// 	@include transition(border .22s ease-in-out, color .22s ease-in-out, background .22s ease-in-out);
// 	text-transform: normal;

// 	&:hover {
// 		text-decoration: none;
// 		border-color: $alpha-link-colorhover;
// 	}
// }

//
// Baseline grid
// ---
// This can be toggled in the _settings.sccs file (default is false)
// There are also options to set the color of the grid and the classname (default is '.holder')
// (inspired by the baseline grid in OOCSS --> http://bit.ly/PF6NhY)
//
@if $show-baseline-grid == true {
    .#{$baseline-holder} {
        background-image: linear-gradient(transparent 50%, $grid-color 50%); // draw the baseline grid
        background-size: em($alpha-baseline-grid) em($alpha-baseline-grid); // calculate the baselinegrid in em's
    }
}

//
// Custom fonts
//
// @font-face {
//     font-family: 'calendas-plus';
//     src: url('../Fonts/calendas_plus-webfont.eot');
//     src: url('../Fonts/calendas_plus-webfont.eot?#iefix') format('embedded-opentype'),
//         url('../Fonts/calendas_plus-webfont.woff') format('woff'),
//         url('../Fonts/calendas_plus-webfont.ttf') format('truetype'),
//         url('../Fonts/calendas_plus-webfont.svg#calendas_plusregular') format('svg');
//     font-weight: normal;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'calendas-plus';
//     src: url('../Fonts/calendas_plus_italic-webfont.eot');
//     src: url('../Fonts/calendas_plus_italic-webfont.eot?#iefix') format('embedded-opentype'),
//         url('../Fonts/calendas_plus_italic-webfont.woff') format('woff'),
//         url('../Fonts/calendas_plus_italic-webfont.ttf') format('truetype'),
//         url('../Fonts/calendas_plus_italic-webfont.svg#calendas_plusitalic') format('svg');
//     font-weight: normal;
//     font-style: italic;
// }

@font-face {
    font-family: 'Gotham-web';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('../Fonts/Gotham-Book.woff2') format('woff2');
}

@font-face {
    font-family: 'Gotham-web';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url('../Fonts/Gotham-BookIta.woff2') format('woff2');
}

@font-face {
    font-family: 'Gotham-web';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url('../Fonts/Gotham-Medium.woff2') format('woff2');
}

// Settings: open and tweak this file.

@import 'settings';

// Vendor Dependencies: add things like bourbon or compass imports here.
@import '../../../node_modules/compass-mixins/lib/compass';

// Shared: do not delete any of this.
@import 'shared/functions';
@import 'shared/extends';
@import 'shared/mixins';
@import 'shared/grid';
@import 'shared/typography';
@import 'shared/ui';

// Patterns: feel free to include only what you need.
@import 'patterns/reset';
@import 'patterns/grid';
@import 'patterns/buttons';
@import 'patterns/forms';
@import 'patterns/alerts';
@import 'patterns/pagination';
@import 'patterns/helpers';
@import 'patterns/media';
@import 'patterns/tables';
@import 'patterns/icons';

// Theme: feel free to delete any or all.
@import 'theme/base';
@import 'theme/typography';
@import 'theme/forms';
@import 'theme/debug';
@import 'theme/usercontent';

// Theme Modules

@import 'theme/modules/frame';
@import 'theme/modules/header';
@import 'theme/modules/home';
@import 'theme/modules/main';
@import 'theme/modules/menu';
@import 'theme/modules/slideshow';
@import 'theme/modules/newsletter';
@import 'theme/modules/photos';
@import 'theme/modules/modal';

// Utilities
@import 'shared/utilities';
@media print {
    @import 'theme/print';
}

.form_hp {
	display: none !important; 
}

.form-col-6 {
	@include box-(12,0,1);

	@include mq($bp3) {
		@include box-(6,0,1);
	}
}

.form-col-12 {
	@include box-(12,0,1);

	@include mq($bp3) {
		@include box-(12,0,1);
	}
	clear: both;
}

.form-col-6, 
.form-col-12 {
	label {
		padding-top: 0;
		font-weight: bold;
	}
}
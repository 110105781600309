//
// Module: menu
//

// Menu widget
.menu-widget-holder {
    border: 2px solid $silver;
    @include padding-tb(1, 1);

    @include mq($bp4) {
        min-height: 14em;
    }
}

.menu-widget {
    h3:after {
        content: '';
    }
    .price {
        color: $alpha-ui-color;
        font-weight: bold;
    }
}

//
// A la carte widget
//

.alacarte-holder {
    @extend %clearfix;
    @include margin-tb(0, 1);

    @include mq($bp4) {
        @include margin-tb(0, 2);
    }

    .menu-col-6 {
        @include box-(12, 1, 1);

        @include mq($bp4) {
            @include box-(6, 1, 1);
        }

        @include mq($bp5) {
            @include box-(4, 1, 1);
        }
    }

    h4 {
        @include text-dimensions(20, 1, 0);
        letter-spacing: 1.7px;
        font-weight: normal;
        text-transform: uppercase;
        border-bottom: 2px solid $silver;
        @include padding-bottom(0.3);
        @include margin-bottom(0.6);
    }

    .cart-items {
        @include list-nav;

        .item {
            @include padding-tb(0, 0.6);
        }

        .item-header {
            overflow-x: hidden;
            max-width: 40em;

            &:before {
                width: 0;
                white-space: nowrap;
                float: left;
                content: '. . . . . . . . . . . . . . . . . . . . ' '. . . . . . . . . . . . . . . . . . . . '
                    '. . . . . . . . . . . . . . . . . . . . ' '. . . . . . . . . . . . . . . . . . . . ';
                color: $gray;
            }

            .title {
                float: left;
                padding-right: 0.5em;
                background: #fff;
                text-transform: uppercase;
                letter-spacing: 1px;
                font-weight: 500;
            }

            .price {
                float: right;
                padding-left: 0.5em;
                background: #fff;
                font-weight: 500;
            }

            .title,
            .price {
                @include text-dimensions(15, 1, 0);
            }
        }

        .description,
        .description p {
            color: $gray;
            padding-top: 0;
        }
    }
}

.icon-highlighted {
    background-image: url('../Img/icon-small-logo.svg');
    background-color: transparent;
    background-position: left center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 25px;
    width: 25px;
    display: block;
    text-indent: -9999px;
    overflow: hidden;
    @include inline-block;
}

//
// Menus widget
//
.menus-holder {
    clear: both;
    @extend %clearfix;
    @include margin-tb(0, 0.5);

    // .fancy {
    //     @include text-dimensions(40, 1.2);
    // }

    .menus {
        border: 2px solid $silver;
        @include padding-tb(1, 1);
        @include box-(12, 1, 1);
        text-align: center;
        margin: 0;
    }

    .price {
        color: $alpha-ui-color;
        font-weight: 500;
    }

    .menu-tabs {
        text-align: center;
        border: none;
    }
}

.menu-tabs-label {
    display: block;
    margin-bottom: 1rem;
}
.menu-tabs {
    @include list-nav;
    @include margin-tb(0, 1);

    // Use grid auto columns to center the columns
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    .tab-content & {
        justify-content: center;
    }

    margin-left: -0.5rem;
    li {
        margin: 0;
        margin-bottom: 0.5rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;

        flex: 0 0 50%;
        @include mq($bp3) {
            flex: 0 0 33.3%;
        }
    }

    li a {
        color: $gray !important;
        border: 1px solid $silver !important;
        outline: 0;
        @include padding-tb(0.4, 0.4);
        text-align: center;
        line-height: 1.4;

        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
    }

    li.active a,
    li a:focus,
    li a:hover {
        color: $alpha-link-color !important;
        border: 1px solid $alpha-link-color !important;
        background: transparent !important;
    }
}

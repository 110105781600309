// Buttons

.btn,
a.btn,
input.btn,
input[type='submit'],
input[type='submit'].btn,
.btn-secondary,
input.btn-secondary,
input[type='submit'].btn-secondary,
a.btn-secondary,
.btn-reservation,
.btn-tab {
    @extend %btn;
    @include btn-l;
    background-color: transparent !important;
    border: 1px solid $alpha-ui-color;
    color: $alpha-ui-color;
    font-weight: normal;
    outline: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: auto;

    &:hover,
    &:focus {
        color: #fff !important;
        background-color: $alpha-ui-color !important;
    }
}

.btn-secondary,
input.btn-secondary,
input[type='submit'].btn-secondary,
a.btn-secondary {
    background-color: $alpha-ui-color !important;
    color: #fff !important;
}

.btn-l {
    @extend .btn;
}

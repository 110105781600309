/*
	Typography
*/

// baseline formula based on the $alpha-line-height variable
// use: @include text-dimensions(#font-size, #line-height, #margin-bottom, #padding-top)

$baseline-unit: $alpha-font-size * $alpha-line-height;
$basefontpercent: $alpha-font-size/16 * 100;

@mixin text-dimensions($fs: 16, $lh: 1, $mb: 1, $pt: 0, $pb: 0){
	// use this for rems
	font-size: #{$fs}px;
	font-size: #{$fs / $alpha-font-size}rem;
	//font-size: #{$fs / $alpha-font-size}em;
	line-height: #{($baseline-unit / $fs) * $lh}em;
	margin-bottom: #{($baseline-unit / $fs) * $mb}em;
	@if $pt > 0 {
		padding-top: #{($baseline-unit / $fs) * $pt}em;
	}
	@if $pb > 0 {
		padding-bottom: #{($baseline-unit / $fs) * $pb}em;
	}
}

@mixin input-dimensions($fs: 16, $lh: 1, $mb: 1, $pt: 0, $pb: 0){
	// use this for rems
	font-size: #{$fs}px;
	font-size: #{$fs / $alpha-font-size}rem;
	//font-size: #{$fs / $alpha-font-size}em;
	line-height: #{($baseline-unit / $fs) * $lh}em;
	height:  #{($baseline-unit / $fs) * $lh}em;
	margin-bottom: #{($baseline-unit / $fs) * $mb}em;
	@if $pt > 0 {
		padding-top: #{($baseline-unit / $fs) * $pt}em;
	}
	@if $pb > 0 {
		padding-bottom: #{($baseline-unit / $fs) * $pt}em;
	}
}


// baseline formulas based on the $alpha-baseline-grid variable
// use: @include baseline-dimensions(#modular-scale-increment, #padding-top, #padding-bottom, #leading)

@mixin baseline-dimensions($msi: 0, $pt: 0, $pb: 0, $l: $alpha-leading) {

	@if $msi == 0 {
		font-size: 1em;
		line-height: ((ceil($alpha-font-size/$alpha-baseline-grid)*$alpha-baseline-grid)+($alpha-baseline-grid*$l))/$alpha-font-size;

		@if $pt > 0 {
			padding-top: $alpha-baseline-grid/$alpha-font-size*$pt*1em;
		}

		@if $pb > 0 {
			padding-bottom: $alpha-baseline-grid/$alpha-font-size*$pb*1em;
		}
	}

	@if $msi != 0 {
		font-size: ms($msi)/$alpha-font-size*1em;
		line-height: ((ceil(ms($msi)/$alpha-baseline-grid)*$alpha-baseline-grid)+($alpha-baseline-grid*$l))/(ms($msi));

		@if $pt > 0 {
			padding-top: $alpha-baseline-grid*$pt/ms($msi)*1em;
		}

		@if $pb > 0 {
			padding-bottom: $alpha-baseline-grid*$pb/ms($msi)*1em;
		}
	}

}
